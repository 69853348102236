<template>
  <div>

    <section class="data-list">
      <table class="tb-data">
        <thead>
          <tr>
            <th>Property name</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(p,k) in property" :key="k">
            <td>
              <div class="item-detail">
                <router-link :to="'/property/'+k" class="item-title">{{ p.name }}</router-link>
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <th>Property name</th>
          </tr>
        </tfoot>
      </table>

    </section>
  </div>
</template>

<script>
import {property} from '@/variables/const.js';

export default {
  page_title: 'All Property',
  data() {
    return {
      property: []
    }
  },
  created() {
    this.$store.commit('setPageTitle', 'All Properties');
    this.$store.commit('setHeadTitle', 'All Properties');

    this.property = property
  },
}
</script>


<style scoped>
.data-list {
  float: none;
  margin: auto;
  max-width: 800px;
}
.tb-data .col-name  {width: 25%;}
.tb-data .col-user  {width: auto;}
.tb-data .col-email {width: 25%;}
.tb-data .col-role  {width: 15%;}
.tb-data .col-date  {width: 15%;}
</style>